import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
const Gallery = () => {
  return (
    <div className="gallary ">
      <section className="about-hero">
        <div className="container">
          <div className="about-content">
            <h1 className="title"> ARETOWN INC.</h1>
            <div className="about-text">
              <h1 className="fs-1 fs-lg-4">OUR GALLERY</h1>
              <p className="fs-sm-5 fs-md-3 fs-5 w-75">
                Explore Our Gallery, Where Aretown's Foundation is Rooted in
                Delivering Value
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row my-5 justify-content-between align-items-center">
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/1.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/2.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/3.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/4.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 mb-3 d-flex justify-content-center col-md-6 col-12">
            <div style={{ width: "60%" }} className="grid-item">
              <Video
                controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                onCanPlayThrough={() => {}}
              >
                <source src={"/assests/gallery/5.mp4"} type="video/mp4" />
                <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src="http://source.vtt"
                  default
                />
              </Video>
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/6.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/7.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/9.jpeg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 mb-3  col-md-6 col-12">
            <div className="grid-item d-flex align-items-center">
              <Video
                controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                onCanPlayThrough={() => {}}
              >
                <source src={"/assests/gallery/8.mp4"} type="video/mp4" />
                <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src="http://source.vtt"
                  default
                />
              </Video>
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/11.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/10.jpeg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/12.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/13.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/14.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 my-3 col-md-6 col-12">
            <div className="grid-item">
              <img src="/assests/gallery/15.jpg" alt="" />
            </div>
          </div>
          <div className="col-lg-4 mb-3  col-md-6 col-12">
            <div className="grid-item d-flex align-items-center">
              <Video
                controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                onCanPlayThrough={() => {}}
              >
                <source src={"/assests/gallery/16.mp4"} type="video/mp4" />
                <track
                  label="English"
                  kind="subtitles"
                  srcLang="en"
                  src="http://source.vtt"
                  default
                />
              </Video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
