import React from "react";
import HeroSection from "../Components/Home/HeroSection";
import AboutUs from "../Components/Home/AboutUs";
import OurCompanies from "../Components/Home/OurCompanies";
import AreTwonServices from "../Components/Home/AreTwonServices";
import EntireNetwork from "../Components/Home/EntireNetwork";
import SuccessPartner from "../Components/Home/SuccessPartner";
import ContactUs from "../Components/Home/ContactUs";

export default function HomePage() {
  return (
    <div className="home">
      <HeroSection />
      <AboutUs />
      <OurCompanies />
      <AreTwonServices />

      <EntireNetwork />
      <SuccessPartner />
      <ContactUs />
    </div>
  );
}
