import React from "react";

export default function HeroSection() {
  return (
    <section className="hero-section">
      <div className="container ">
        <div className="row align-items-center">
          <div
            data-aos="fade-left"
            className="col-lg-7  mb-lg-5  col-md-6 col-12"
          >
            <h1>Aretown </h1>
            <p>Sparks Value Throughout Diversity</p>
          </div>
          <div
            data-aos="fade-right"
            className="col-lg-5 img-holder col-md-6 col-12"
          >
            <img
              style={{ width: "100%" }}
              src="/assests/images/homepage-bg.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
