import React from "react";

const AboutHero = () => {
  return (
    <section className="about-hero">
      <div className="container">
        <div className="about-content">
          <h1 className="title"> ARETOWN INC.</h1>
          <div className="about-text">
            <h1 className="fs-1 fs-lg-4">ABOUT US</h1>
            <p className="fs-sm-5 fs-md-3 fs-5 w-75">
              Our foundation in Aretown is built on the core principle of
              delivering value.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHero;
