import React from "react";
import classes from "./AretwonServicesCard.module.css";
export default function AretwonServicesCard(props) {
  const alignDir = props.i % 2 == 0 ? "left" : "right";
  return (
    <a
      data-aos={props.i % 2 == 0 ? "zoom-in-right" : "zoom-in-left"}
      data-aos-offset="500"
      href={props.href}
      className={`${classes.holder} ${classes[alignDir]}`}
    >
      <a href={props.href} className={classes.imgHolder}>
        <img src={props.img} alt="" />
      </a>
      <div className={classes.text}>
        <h1>{props.title}</h1>
        <p>{props.content}</p>
      </div>
    </a>
  );
}
