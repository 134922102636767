import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Util/Navbar";
import HomePage from "./Pages/HomePage";
import { useEffect } from "react";
import Aos from "aos";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Metrics from "./Pages/Metrics";
import Gallery from "./Pages/Gallery";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route index element={<Navigate replace to="home" />} />
          <Route path="home" element={<HomePage />} />
          <Route path="about" element={<AboutUs />} />
          <Route path="gallary" element={<Gallery />} />
          <Route path="metrics" element={<Metrics />} />
          <Route path="contact" element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
