import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const ContactUs = (props) => {
  const [subject, setSubject] = useState("");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_mmh74ep", "template_3zoc3gu", form.current, {
        publicKey: "mEymesX3OAukmrq9r",
      })
      .then(
        () => {
          alert("Your Message has been received thank you ❤️");
          window.location.reload();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  const RadioButton = ({ id, label, onClick }) => (
    <div className="radio-button">
      <input
        name="radio-group"
        id={id}
        className="radio-button__input"
        type="radio"
        onClick={() => {
          onClick();
        }}
      />
      <label htmlFor={id} className="radio-button__label">
        <span className="radio-button__custom"></span>
        {label}
      </label>
    </div>
  );
  return (
    <section id="contact-us" className="contact-us">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 col-12 text-holder">
            <h1>Contact Us. It’s Easy.</h1>
            <p>
              This Brand franchise opprtunities around Canada. Please fill out
              your contacts and will be in touch with you
            </p>
            <a className="call-phone mb-4 text-decoration-none text-white gap-3 align-items-center d-flex">
              <div className="img-holder">
                <img src="/assests/images/phone.svg" alt="" />
              </div>
              <div>
                <h5>Call Today</h5>
                <h6>+1 6039990357</h6>
              </div>
            </a>
          </div>
          <div className="col-lg-6 col-12">
            <form ref={form} onSubmit={sendEmail} action="">
              <input
                className="d-none"
                name="subject"
                id="subject"
                value={subject}
                type="text"
              />
              <div className="row justify-content-between">
                <div className="col-md-6 col-12">
                  {" "}
                  <div className="input-holder ">
                    <label htmlFor="">Full Name</label>
                    <input type="text" name="name" id="" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {" "}
                  <div className="input-holder ">
                    <label htmlFor="">Email </label>
                    <input type="text" name="email" id="" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {" "}
                  <div className="input-holder ">
                    <label htmlFor="">Phone </label>
                    <input type="text" name="phone" id="" />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  {" "}
                  <div className="input-holder ">
                    <label htmlFor="">Company(optional) </label>
                    <input type="text" name="company" id="" />
                  </div>
                </div>
              </div>
              <div className="contact-form-btn-holder mb-4">
                <div className="radio-buttons-container">
                  <RadioButton
                    id="radio1"
                    onClick={() => {
                      setSubject("Requesting Franchise ");
                      console.log(form.current);
                    }}
                    label="Requesting Franchise "
                  />
                  <RadioButton
                    id="radio2"
                    onClick={() => {
                      setSubject("Feedback ");
                      console.log(form.current);
                    }}
                    label="Feedback"
                  />
                  <RadioButton
                    id="radio3"
                    onClick={() => {
                      setSubject("Comment");
                      console.log(form.current);
                    }}
                    label="Comment"
                  />
                </div>
              </div>

              <div className="row">
                <div className=" col-12">
                  {" "}
                  <div className="input-holder ">
                    <label htmlFor="">Message</label>
                    <textarea rows={6} type="text" name="message" id="" />
                  </div>
                </div>
              </div>
              <button>Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
