import React, { useState } from 'react';
import classes from './collapse.module.css';
const Collapse = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={` ${classes.collapse} ${isOpen ? classes.open : null}`}>
      <div className={classes.collapseItem} onClick={toggleCollapse}>
        <div className={classes.collapseIcon}>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        <h2>{title}</h2>
      </div>

      <div className={classes.collapseDescription}>{description}</div>
    </div>
  );
};

export default Collapse;
