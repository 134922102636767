import React from 'react';
import AboutHero from '../Components/aboutus/AboutHero';
import OurVision from '../Components/aboutus/OurVision';
import OurStrategic from '../Components/aboutus/OurStrategic';
import Values from '../Components/aboutus/Values';

const AboutUs = () => {
  return (
    <section className='about-us'>
      <AboutHero />
      <OurVision />
      <OurStrategic />
      <Values />
    </section>
  );
};

export default AboutUs;
