import React from "react";
import AretwonServicesCard from "../../shared/AretwonServicesCard";

export default function AreTwonServices() {
  const data = [
    {
      href: "",
      img: "/assests/images/service1.png",
      title: "Business Consulting",
      content:
        "At the heart of our expertise lies business consulting, where we collaborate with clients to unlock their full potential. Our seasoned consultants bring strategic insights, market intelligence, and tailored solutions to propel businesses forward, fostering growth and resilience.",
    },
    {
      href: "",
      img: "/assests/images/service2.png",
      title: "Product Development",
      content:
        "In the realm of product development, we breathe life into ideas. From conceptualization to market-ready prototypes, our team harnesses creativity and technical prowess to bring innovative products that captivate and resonate with your audience.",
    },
    {
      href: "https://www.sleepysnuggles.ca/",
      img: "/assests/images/service3.png",
      title: "Daycare Services",
      content:
        "Nurturing the future, our daycare services provide a safe and stimulating environment for young minds to thrive. Committed to excellence in childcare, we prioritize a holistic approach, fostering emotional, social, and cognitive development",
    },
    {
      href: "https://thegogrill.com/",
      img: "/assests/images/service4.png",
      title: "Fast Healthy Food",
      content:
        "Satisfying both taste buds and nutritional needs, our fast healthy food offerings redefine the concept of on-the-go dining. From wholesome salads to delectable wraps, our menu is crafted for those who seek convenience without compromising on well- being.",
    },
    {
      href: "https://phomi-canada.com/",
      img: "/assests/images/service5.png",
      title: "Organic Wall Covering",
      content:
        "Elevate your spaces with our exquisite range of organic wall coverings. Meticulously crafted from sustainable materials, our designs seamlessly blend aesthetics with environmental consciousness, transforming interiors into sustainable sanctuaries",
    },
    {
      href: "https://leapai-canada.com/",
      img: "/assests/images/service6.png",
      title: "Technology Solutions",
      content:
        "In the ever-evolving tech landscape, we provide innovative solutions tailored to meet the challenges of the digital age. Our tech experts leverage cutting-edge tools to empower businesses, ensuring they stay ahead in an era of rapid technological advancement. Chatbots are used to conduct on-line chat conversations via text or text-to-speech.........",
    },
  ];
  return (
    <section className="aretwon-services">
      <h1 className="section-header mb-5">Aretown Services</h1>
      <div className="container">
        {data.map((e, i) => {
          return <AretwonServicesCard key={i} i={i} {...e} />;
        })}
      </div>
    </section>
  );
}
