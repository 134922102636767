import React from "react";

export default function EntireNetwork() {
  return (
    <section className="entire-network">
      <div data-aos="fade-up" data-aos-offset="400" className="container">
        <div className="row align-items-center">
          <div className="img-holder p-5 col-md-6 col-12">
            <img
              style={{ width: "100%" }}
              src="/assests/images/entire.png"
              alt=""
            />
          </div>
          <div className="text-holder col-md-6 col-12">
            <h1 className="mb-1"> Our Consultation Offering </h1>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                marginBottom: "30px",
              }}
            >
              Our consultation will contribute to the success and efficiency of
              your organization in various endeavors. We will help you build
              your strategy, business plan, and plan for your product
              development. planning is a valuable tool for achieving goals,
              optimizing resources, mitigating risks, and improving
              decision-making.
            </p>
            <div className="info">
              <h2> Goal Achievement</h2>
              <p>
                We provide a roadmap for achieving goals. It involves setting
                clear objectives, outlining the steps needed to reach them, and
                establishing timelines. This structured approach helps
                individuals and organizations stay focused on their objectives.
              </p>
            </div>
            <div className="info">
              <h2>Resource Optimization</h2>
              <p>
                We identify priorities and allocate resources efficiently.
                Proper planning helps prevent wastage and ensures that resources
                are utilized to their maximum potential, leading to
                cost-effectiveness and improved productivity.
              </p>
            </div>
            <div className="info">
              <h2> Risk Mitigation</h2>
              <p>
                Planning involves anticipating potential challenges and risks.
                Individuals and organizations can develop contingency plans and
                strategies to mitigate risks. This proactive approach enhances
                resilience and minimizes the negative impact of unforeseen
                events, contributing to overall success.
              </p>
            </div>
            <div className="info">
              <h2> Improved Decision-Making </h2>
              <p>
                Planning facilitates informed decision-making. When goals,
                options, and potential outcomes are thoroughly considered during
                the planning process, decision-makers have a clearer
                understanding of the implications of their choices.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
