import React from "react";

export default function AboutUs() {
  return (
    <section data-aos="fade-up" className="about-us">
      <div className="container ">
        <div className="row about-holder justify-content-center align-items-center">
          <div className="col-lg-6  mb-lg-5  col-12">
            <h1>About Us </h1>
            <p>
              Our foundation in Aretwon is built on the core principle of
              delivering value. In every facet of our operations, we prioritize
              value creation for our clients, stakeholders, and the communities
              we serve. Here, value isn't just a metric; it's a commitment that
              defines our identity and guides our every endeavor.
            </p>
          </div>
          <div className="col-lg-6 img-holder col-12">
            <img
              style={{ width: "100%" }}
              src="/assests/images/homepage-about.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
