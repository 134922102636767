import React from "react";
import Collapse from "../../shared/Collapse";

export default function Values() {
  const values = [
    {
      title: "Excellence",
      description:
        "We set and strive for high standards, aiming for excellence in every aspect of our work.",
    },
    {
      title: "Integrity",
      description:
        "We uphold the highest ethical standards, fostering transparency, honesty, and accountability.",
    },
    {
      title: " Innovation",
      description:
        "Embracing a culture of innovation, we explore new ideas, technologies, and approaches to stay at the forefront of industry advancements.",
    },
    {
      title: "Client-Centric Focus",
      description:
        "Our clients are central to our endeavors, and we are dedicated to understanding and exceeding their expectations.",
    },
    {
      title: "Sustainability",
      description:
        "We are committed to sustainable business practices, balancing economic growth with environmental and social responsibility.",
    },
  ];
  return (
    <section className="container corporate-values">
      <h1 className="section-header mb-5">Aretown Corporate Values</h1>
      <div className="row align-items-center">
        <div style={{ paddingRight: "50px" }} className="col-lg-6 col-12">
          <img
            style={{ width: "100%" }}
            src="/assests/images/Astronaut-bro.png"
            alt=""
          />
        </div>
        <div className="col-lg-6 col-12">
          <div className="collapeseItems mb-4 ">
            {values.map((value, index) => {
              return (
                <Collapse title={value.title} description={value.description} />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
