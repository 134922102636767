import React from "react";

const OurStrategic = () => {
  return (
    <section className="our-strategic">
      <div className="container">
        <div>
          <div className="strategic-head">
            <h1 className="section-header">Aretown Strategic Objectives</h1>
            <p>
              We aspire to deliver meaningful value to our stakeholders across
              our diverse ventures.Aretown strategic objectives
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="stratigic-card ">
                <h5 className="stratigic-title">
                  1. Diversification Excellence
                </h5>
                <p className="stratigic-text">
                  Expand and diversify the investment portfolio by identifying
                  and entering new sectors or industries, aligning with emerging
                  trends and market demands.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="stratigic-card ">
                <h5 className="stratigic-title">
                  2. Value-Driven Partnerships
                </h5>
                <p className="stratigic-text">
                  Establish strategic partnerships with like-minded entities
                  that share a commitment to value creation, leveraging
                  collaborative efforts to enhance the overall strength and
                  impact of each investment.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="stratigic-card ">
                <h5 className="stratigic-title">3. Client-Centric Ventures </h5>
                <p className="stratigic-text">
                  Prioritize customer-centric approaches in ventures, ensuring
                  that each business unit focuses on delivering value that
                  directly addresses the needs and expectations of end-users or
                  clients.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="stratigic-card ">
                <h5 className="stratigic-title">4. Sustainable Growth</h5>
                <p className="stratigic-text">
                  Implement sustainable business practices across all ventures,
                  balancing economic growth with environmental and social
                  responsibility to create long-term value for both stakeholders
                  and the wider community.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="stratigic-card ">
                <h5 className="stratigic-title">5. Operational Excellence</h5>
                <p className="stratigic-text">
                  Optimize operational processes within each business unit,
                  leveraging technology and best practices to enhance
                  efficiency, reduce costs, and maximize the overall
                  effectiveness of the investment portfolio.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStrategic;
