// Navbar.js
import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { NavLink } from "react-router-dom";
const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 47)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 67)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="52"
        height="4"
        rx="2"
        transform="translate(294 57)"
        fill="#574c4c"
      />
    </g>
  </svg>
);
const Navbar = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  return (
    // <div className={styles.navContainer}>
    //   <div className={styles.brand}>
    //     <a href="#!">Logo</a>
    //   </div>
    //   <nav>
    //     <div className={styles.navMobile} onClick={toggleNav}>
    //       <a id="navbar-toggle" href="#!">
    //         <span></span>
    //       </a>
    //     </div>
    //     <ul
    //       className={`${styles.navList} ${isNavOpen ? styles.open : ""}`}
    //       onClick={closeNav}
    //     >
    //       <li>
    //         <a href="#!">Home</a>
    //       </li>
    //       <li>
    //         <a href="#!">About</a>
    //       </li>

    //       <li>
    //         <a href="#!">Contact</a>
    //       </li>
    //     </ul>
    //   </nav>
    // </div>
    <div className="main-navbar">
      <header className="header-main dark">
        <nav className="navbar">
          <div className="container">
            <div className="logo">
              <a href="/">
                <img
                  style={{ width: "100%" }}
                  src="/assests/images/logo.png"
                  alt=""
                />
              </a>
            </div>
            <div className="menu-icon" onClick={handleShowNavbar}>
              <Hamburger />
            </div>
            <div className={`nav-elements  ${showNavbar && "active"}`}>
              <ul>
                <li>
                  <NavLink to="/home">Home</NavLink>
                </li>

                <li>
                  <NavLink to="/about">About</NavLink>
                </li>
                <li>
                  <NavLink to="/gallary">Gallery</NavLink>
                </li>
                <a href="#contact-us" className="contact-resp-btn">
                  Contact
                </a>
              </ul>
            </div>
            <div className="contact-btn">
              <a href="/home#contact-us">CONTACT</a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Navbar;
