import React from "react";

export default function OurCompanies() {
  return (
    <section className="our-companies">
      <div className="container">
        <h1 data-aos="fade-down">Trusted by businesses worldwide</h1>
        <div className="row justify-content-between">
          <a
            data-aos-delay="300"
            data-aos="zoom-in"
            href="https://phomi-canada.com/"
            className="img-holder"
          >
            <img src="/assests/images/company-1.png" alt="" />
          </a>
          <a
            data-aos-delay="500"
            data-aos="zoom-in"
            href="https://leapai.ai/"
            className=" img-holder"
          >
            <img src="/assests/images/company-2.png" alt="" />
          </a>
          <a
            data-aos-delay="700"
            data-aos="zoom-in"
            href="https://thegogrill.com/"
            className="img-holder"
          >
            <img src="/assests/images/company-3.png" alt="" />
          </a>
          <a
            data-aos-delay="900"
            data-aos="zoom-in"
            href="https://www.sleepysnuggles.ca/"
            className=" img-holder"
          >
            <img src="/assests/images/company-4.png" alt="" />
          </a>
        </div>
      </div>
    </section>
  );
}
