import React from "react";

export default function SuccessPartner() {
  return (
    <section className="success-partner">
      <div className="container">
        <h1 className="section-header mb-5">Success Partners</h1>
        <div className="partner-holder py-3 d-flex justify-content-between">
          <div className="partner-card">
            <img src="/assests/images/partner-1.png" alt="" />
          </div>
          <div className="partner-card">
            <img src="/assests/images/partner-2.png" alt="" />
          </div>
          <div className="partner-card">
            <img src="/assests/images/partner-3.png" alt="" />
          </div>
          <div className="partner-card">
            <img src="/assests/images/partner-4.png" alt="" />
          </div>
          <div className="partner-card">
            <img src="/assests/images/partner-5.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
