import React from "react";

const OurVision = () => {
  return (
    <section className="our-vision">
      <div className="container">
        <div className="our-vision-holder row mx-auto about-holder justify-content-between align-items-center">
          <div className="our-vision-text col-lg-6 mb-lg-5 col-md-12 col-12">
            <h1 className="fs-1 ">Vision Aretown Inc.</h1>
            <p className="">
              We aspire to deliver <span>meaningful value</span> to our
              stakeholders across our diverse ventures
            </p>
          </div>
          <div className="our-vision-img p-5 col-lg-6 img-holder col-12">
            <img src="/assests/images/ourVision2.png" alt="visionimage" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurVision;
